var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"redaction-dashboard-layout"},[_c('v-row',{staticClass:"mt-1 fullprofilecontainer ml-0 mr-0",class:{
			palr5: _vm.$vuetify.breakpoint.smAndDown,
			palr10: _vm.$vuetify.breakpoint.mdAndUp,
		}},[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-col',{attrs:{"md":"12","cols":"12"}},[(_vm.user)?_c('div',{staticClass:"text-center"},[_c('OpenProfileModal',{attrs:{"profileData":_vm.user,"formationData":_vm.formationProgress.formation,"disableProfileModal":true}}),_c('h2',[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name))])],1):_vm._e()]),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"text-center pr-0 pl-0",attrs:{"md":"12","cols":"12"}},[(_vm.formationProgress)?_c('TodaySession',{attrs:{"formationProgress":_vm.formationProgress}}):_vm._e()],1):_vm._e(),(this.formationProgress)?_c('div',{attrs:{"id":"ParcoursBlock"}},[(_vm.$vuetify.breakpoint.mdAndUp == false)?_c('v-col',{staticClass:"shadow-blue-not-openned mb20",class:{ 'd-none': _vm.currentRouteName === 'Category Detail' },attrs:{"id":"ParcoursBlockMobile","cols":"12"}},[_c('ListParcoursSession',{attrs:{"formationProgress":_vm.formationProgress}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown == false)?_c('v-col',{staticClass:"shadow-blue-not-openned mb20",staticStyle:{"border-radius":"5px !important"},attrs:{"id":"ParcoursBlockDesktop","cols":"12"}},[_c('ListParcoursSession',{attrs:{"formationProgress":_vm.formationProgress}})],1):_vm._e()],1):_c('ListParcoursSkeleton'),(_vm.user && _vm.user.entreprise_id.slug == 'demo')?_c('v-alert',{class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"display":"block"},attrs:{"border":"left","type":"info","color":"#8186e8"}},[_c('v-chip',{staticClass:"mb-2",attrs:{"color":"#686bdf","text-color":"white"}},[_c('span',[_vm._v("🔥 "),_c('b',[_vm._v("Débloqué")])])]),_c('h3',{staticClass:"mb-2"},[_vm._v("Ton parcours de rédaction est enfin disponible !")]),_c('a',{staticClass:"fs12 ortho-bold white--text d-flex",attrs:{"href":"/redaction"}},[_vm._v(" COMMENCER"),_c('v-icon',{staticClass:"mr3",attrs:{"color":"white","small":""}},[_vm._v("mdi-arrow-right")])],1)],1):_vm._e(),(
					_vm.user &&
					_vm.user.entreprise_id &&
					_vm.user.entreprise_id._id == '5f6c994eac759a00173cef92' &&
					_vm.checkIfEndDateApprochingTenDaysEnd() === true
				)?_c('v-alert',{staticStyle:{"display":"block"},attrs:{"border":"left","type":"info","color":"#8186e8"}},[_c('v-chip',{staticClass:"mb-2",attrs:{"color":"#686bdf","text-color":"white"}},[_c('div',{staticClass:"ortho-button-exercice-container"},[_c('img',{staticClass:"speakerEmoji",attrs:{"src":require("@/assets/icons/chrono.png"),"alt":"chrono"}}),_c('span',[_c('b',[_vm._v("À court de temps ?")])])])]),_c('h3',{staticClass:"mb-2"},[_vm._v("Gagne 1 mois de formation en plus !")]),_c('a',{staticClass:"fs12 ortho-bold white--text",attrs:{"href":"https://orthographiq.webflow.io/parrainez-vos-proches-pour-orthographiq?utm_source=app&utm_medium=dashboard-side-section&utm_campaign=parrainage","target":"_blank"},on:{"click":function($event){return _vm.goToReferralPage()}}},[_vm._v("PARRAINER MES PROCHES"),_c('v-icon',[_vm._v("mdi-menu-right")])],1)],1):_vm._e(),_c('v-col',{staticClass:"profileManagementContainer",class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12"}},[(_vm.user)?_c('ProfileManagement',{attrs:{"profileData":_vm.user}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[(this.$router.currentRoute.name != 'Leaderboard Weekly')?_c('ProgressionBarForDashboardRedaction',{class:{ 'd-none': _vm.$vuetify.breakpoint.smAndDown }}):_vm._e(),_c('router-view')],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('div',[(_vm.formationProgress && !_vm.$vuetify.breakpoint.smAndDown)?_c('TodaySession',{attrs:{"formationProgress":_vm.formationProgress}}):_vm._e()],1),_c('v-col',{class:{ 'd-none': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12"}},[(_vm.user)?_c('ProfileManagement',{attrs:{"profileData":_vm.user}}):_vm._e()],1)],1)],1),(
			!_vm.isSwitchFormationStateLoading &&
			_vm.formationProgress &&
			!_vm.formationProgress.formation.last_session_id &&
			!_vm.formationProgress.formation.last_category &&
			_vm.formationProgress.formation.formation_finished !== true &&
			_vm.formationProgress.formation.last_exercice_date
		)?_c('AlertChoiceCategorySession',{attrs:{"formationProgress":_vm.formationProgress}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }